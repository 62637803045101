import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const GalleryMusicAnarquiaVertical = () => {
	const gallery = useStaticQuery(graphql`
	    query {
	        data: allFile(filter: {relativeDirectory: {eq: "music/anarquia-vertical"}}) {
	          edges {
	            node {
	              childImageSharp {
			          fluid(maxWidth: 800) {
			             ...GatsbyImageSharpFluid
			        }
			      }
	            }
	          }
	        }
	      }
	 `)

	var imagesGallery = []

	for (var i = gallery.data.edges.length - 1; i >= 0; i--) {
		imagesGallery.push(<img src={gallery.data.edges[i].node.childImageSharp.fluid.src} />)
	}

  return imagesGallery
}

export default GalleryMusicAnarquiaVertical