import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GalleryMusicIrreal from "../components/galleryMusicIrreal"
import GalleryMusicAlgara from "../components/galleryMusicAlgara"
import GalleryMusicUltra from "../components/galleryMusicUltra"
import GalleryMusicNuevaFuerza from "../components/galleryMusicNuevaFuerza"
import GalleryMusicAnarquiaVertical from "../components/galleryMusicAnarquiaVertical"

const Music = () => (
  <Layout>
    <SEO title="Music" />
    <h1>Music published</h1>
    <p>Photography is my second main interest after music, here there are my albums published after years of creating underground music.</p>
    <h2>Irreal</h2>
    <div class="gallery album-covers">
    	<GalleryMusicIrreal />
    </div>
    <p>All three albums published by La Vida Es Un Mus. You can listen to Irreal in Bandcamp and Spotify.</p>
    <hr />
    <h2>Ultra</h2>
    <div class="gallery album-covers">
    	<GalleryMusicUltra />
    </div>
    <p>All albums published by La Vida Es Un Mus. You can listen to Ultra in Bandcamp and Spotify.</p>
    <hr />
    <h2>Nueva Fuerza</h2>
    <div class="gallery album-covers">
    	<GalleryMusicNuevaFuerza />
    </div>
    <p>The LP was published by La Vida Es Un Mus and the EP was published by Discos Enfermos. You can listen to Nueva Fuerza in Bandcamp and Spotify.</p>
    <hr />
    <h2>Anarquía Vertical</h2>
    <div class="gallery album-covers">
    	<GalleryMusicAnarquiaVertical />
    </div>
    <p>The LP was published by La Vida Es Un Mus and the EP was published by Antitodo. You can listen to Anarquía Vertical in Bandcamp and Spotify.</p>
  </Layout>
)

export default Music
